@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.heading {
  animation: slide-down 1s ease-out;
}

.section_active {
  animation: slide-down 1s ease-out;
}

.section_past {
  animation: slide-down 1s ease-out 0.3s;
}