@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
  
@keyframes enlarge {
  0% {
    transform: scale(1); 
  }
  100% {
    transform: scale(1.5); 
  }
}