html,
body {
  margin: 0;
  padding: 0;
  background-color: #FEFEFE;
  background-color: #FFFFFF;
  background-color: #939393;
  background-color: #737373;
  background-color: #818181;
  background-color: #BFBFBF;
  background-color: #BFBFBF;
  background-color: #FFFFFF;
  background-color: #FFFFFF;
  background-color: #1785FF;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2323aaff'/%3E%3Cstop offset='1' stop-color='%2323aaff' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231348ff'/%3E%3Cstop offset='1' stop-color='%231348ff' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%232070ff'/%3E%3Cstop offset='1' stop-color='%232070ff' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231785FF'/%3E%3Cstop offset='1' stop-color='%231785FF' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2330CBFF'/%3E%3Cstop offset='1' stop-color='%2330CBFF' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23150FFF'/%3E%3Cstop offset='1' stop-color='%23150FFF' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");

  background-attachment: fixed;
  background-size: cover;
background-attachment: fixed;
background-size: cover;;
}





.container {
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  height: 100vh;
  text-align: center; 
}

.background-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  opacity: 0.2;
  z-index: -1;
}

.content {
  display: flex;
  color: white; 
  font-size: 36px;
  flex-direction: column;
  align-items: center;
  margin-top: 30vh; 
  animation: slide-down 1s ease-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
}

.social-icons a {
  margin: 0 10px;
}